import {Component,ViewChild,AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {Service} from './app.http';
export class AppModuleShared { }
export interface itemList {
  Image: string;
  Name: string;
  ItemID: string;
  Type: string;
}

/**
 * @title Table with filtering
 */
@Component({
   selector: 'app-home',
   templateUrl: './app.home.html',
   styleUrls: [ './app.home.css' ]
})

export class HomeComponent implements OnInit{
  displayedColumns: string[] = ['Image', 'Name', 'ItemID', 'Type'];
  dataSource = new MatTableDataSource();
  itemIDs = [];

  constructor(private Service: Service, private cdr: ChangeDetectorRef) {
  }
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  ngOnInit() {
    this.Service.refreshList().subscribe((data: any[])=>{
      this.itemIDs = data;
      this.dataSource = new MatTableDataSource(this.itemIDs);
      this.cdr.detectChanges();
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    )
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


   
  }
  


