import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
export interface itemList {
  Image: string;
  Name: string;
  ItemID: string;
  Type: string;
}

@Injectable({
  providedIn: 'root'
})
export class Service {

  itemList: itemList[];

  constructor(private _http: HttpClient) { }

  refreshList(){
    
   //return this._http.get("http://localhost:6565/ItemID/AllItemID").pipe(
    return this._http.get("https://valheimitemids.com/ItemID/AllItemID").pipe(
             map(this.extractData));
 }
 private extractData(res: Response) {
  return res || []; // If 'res' is null, it returns empty object
}
}