import {Component} from '@angular/core';

export class AppModuleShared { }
/**
 * @title Cheats List
 */
@Component({
   selector: 'app-root',
  templateUrl: './app.cheats.html',
  styleUrls: [ './app.cheats.css' ]
})

export class CheatComponent{ }
  
 

  
  


