import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/app.home.component';
import { CheatComponent } from './cheat/app.cheats';

export const routes: Routes = [
  {
      path: '',
      pathMatch: 'full',
      component: HomeComponent
  },
  {
      path: 'cheat',
      component: CheatComponent
  },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
export class AppRoutingModule { }