import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
  <header>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3 bg-brand">
        <div class="container navbar-flex">
          <div class="brand-centered">
            <a class="navbar-brand"><img alt="Valheim Item IDs" class="brand-image" height="50" src="assets/images/headerlogo.png" style="margin-top: 2px;"/></a>
          </div><button aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarResponsive" data-toggle="collapse" type="button"><span class="navbar-toggler-icon"></span></button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav" style="justify-content: center; width: 100%;">
              <li class="nav-item">
                <a class="nav-link" routerLink="">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="cheat">Cheats</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" routerLink="types">Sort by Type</a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" href="https://www.reddit.com/r/valheim/comments/lig8ml/spawn_item_command_list/"><span>Reddit</span></a>
              </li>
            </ul>
          </div>
        </div>
      
    </nav>
  </header>
  `,
  styles: []
})
export class HeaderComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
